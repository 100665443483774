<template>
  <div id="contentpage" class="bg-content-pages section">
    <div class="container">
      <BrandLogo></BrandLogo>
      <HomeButton></HomeButton>
      <Imprint v-if="$route.params.id == 'impressum'"/>
      <DataProtection v-if="$route.params.id == 'datenschutz'"/>
      </div>
    </div>
</template>

<script>
'use strict'
import Imprint from '../Sections/Imprint.vue'
import DataProtection from '../Sections/DataProtection.vue'
import HomeButton from '../Elements/HomeButton.vue'
import BrandLogo from '../Elements/BrandLogo.vue'

export default {
  name: 'ContentPage',
  components: {
    Imprint,
    DataProtection,
    HomeButton,
    BrandLogo
  },
  props: ['id'],
  created () {
    window.jQuery('body,html').animate({
      scrollTop: 0
    }, 800)
  }
}
</script>
