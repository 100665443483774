<template>
  <div id="noerdlicheDigitaleApp">
    <router-view></router-view>
    <footer-section></footer-section>
  </div>
</template>

<script>
'use strict'
import FooterSection from './components/Elements/FooterSection.vue'

export default {
  name: 'noerdlicheDigitaleApp',
  components: {
    FooterSection
  }
}
</script>
