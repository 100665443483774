<template>
    <!--START ABOUT US-->
        <section class="section scroll-select" id="about">
            <div class="container">
                <div class="row vertical-content">
                    <div class="col-lg-6">
                        <div class="features-box">
                            <h3>Über uns</h3>
                            <p class="text-muted web-desc">
                                Die Zusammenarbeit und das Vertrauen von Menschen untereinander ist die Grundlage
                                unserer Arbeit. Wir glauben daran, dass eine gute Beziehung
                                die Basis und Voraussetzung für außergewöhnliche Ergebnisse sind.
                            </p>
                            <p class="text-muted text-center">"Unser Ziel ist es, eine langjährige, vertrauensvolle Basis mit unseren Kunden aufzubauen"</p>
                            <p class="text-muted web-desc">
                                Darum schauen wir zuerst, ob die Chemie stimmt, denn Vertrauen ist einer
                                unserer Werte, der durch Offenheit, Ehrlichkeit und Kontinuität erreicht wird und
                                den wir täglich in unserer Arbeit leben.
                            </p>
                            <p class="text-muted web-desc">
                                Zu guten Ergebnissen gehört aber auch Kompetenz und die besteht aus Wissen und Erfahrung.
                                Beides haben wir uns über jahrelange Arbeit in vielen, teilweise auch mal kritischen, Projekten angeeignet.
                            </p>
                            <p class="text-muted web-desc">
                                E-Business ist unsere Leidenschaft und wir freuen uns jedesmal, wenn wir
                                dazu eine neue Erkenntnis finden. Und wer Spaß an einer Sache hat, macht
                                sie gut!
                            </p>
                            <p class="text-muted web-desc">
                                Haben Sie Interesse, mit uns zusammen zu arbeiten? Dann freuen
                                wir uns, wenn Sie uns kontaktieren!
                            </p>
                            <p>
                                Ein erstes Gespräch ist der Schlüssel zu unserem weiteren, gemeinsamen Weg.
                            </p>
                            <a href="mailto:info@noerdlichedigitale.de" class="btn btn-custom margin-t-30 waves-effect waves-light">Kontaktieren Sie uns einfach hier<i class="mdi mdi-arrow-right"></i></a>
                        </div>
                    </div>
                    <!--
                    <div class="col-lg-7">
                        <div class="features-img features-right text-right">
                            <img src="images/brain.svg" alt="macbook image" class="img-fluid">
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </section>
        <!--END ABOUT US-->
</template>

<script>
export default {
  name: 'About',
  props: {
  }
}
</script>
