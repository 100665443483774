<template>
<!-- STRAT NAVBAR -->
        <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
            <div class="container">
                <BrandLogo></BrandLogo>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="mdi mdi-menu"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav navbar-center" id="mySidenav">
                        <li class="nav-item active">
                            <a href="#home" class="nav-link">Start</a>
                        </li>
                        <li class="nav-item">
                            <a href="#services" class="nav-link">Unser Service</a>
                        </li>
                        <!--
                        <li class="nav-item">
                            <a href="#team" class="nav-link">Team</a>
                        </li>
                        -->
                        <li class="nav-item">
                            <a href="#start-process" class="nav-link">Wie wir arbeiten</a>
                        </li>
                        <li class="nav-item">
                            <a href="#about" class="nav-link">Über uns</a>
                        </li>
                        <li class="nav-item">
                            <a href="#partner" class="nav-link">Partner</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
         <!-- END NAVBAR -->
</template>

<script>
import BrandLogo from '../Elements/BrandLogo.vue'

export default {
  name: 'Navbar',
  components: {
    BrandLogo
  },
  props: {
  },
  methods: {
    navigate: function () {
      window.location.href = '/register'
    }
  }
}
</script>
