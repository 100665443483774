<template>
    <!--START SERVICES-->
        <section class="section pt-5 scroll-select" id="services">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <h1 class="section-title text-center">Was wir Ihnen bieten</h1>
                        <div class="section-title-border margin-t-20"></div>
                        <p class="section-subtitle text-muted text-center padding-t-30 font-secondary">Unser Ziel ist eine langjährige, vertrauensvolle Basis mit unseren Kunden aufzubauen und das über den kompletten Projektzyklus hinweg - und auch darüber hinaus.
                            Dabei bringen wir das richtige Handwerkszeug mit, um Sie tatkräftig zu unterstützen.
                        </p>
                    </div>
                </div>
                <div class="row margin-t-30">
                    <div class="col-lg-6 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <i class="pe-7s-diamond text-custom"></i>
                            <h4 class="padding-t-15">Kompetenz</h4>
                            <p class="padding-t-15 text-muted">Wir verfügen über langjährige Erfahrung in den Bereichen Digitalisierung und E-Business.
                              Dazu gehört die Beratung und Implementierung von Shop-Systemen, Ihrer Webseite und den Prozessen rund um Shop, PIM, DAM und CMS.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <i class="pe-7s-display2 text-custom"></i>
                            <h4 class="padding-t-15">Beratung</h4>
                            <p class="padding-t-15 text-muted">Wir begleiten Sie von Anfang an und helfen Ihnen bei Produktauswahl, Projektausrichtung und -umsetzung.</p>
                        </div>
                    </div>
                </div>
                <div class="row margin-t-30">
                    <div class="col-lg-6 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <i class="pe-7s-piggy text-custom"></i>
                            <h4 class="padding-t-15">Strategische Lösungen</h4>
                            <p class="padding-t-15 text-muted">Wir helfen Ihnen, Lösungen zu finden, die sich optimal
                                 in die strategische Ausrichtung ihres Unternehmens einfügen.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <i class="pe-7s-monitor text-custom"></i>
                            <h4 class="padding-t-15">Technologie</h4>
                            <p class="padding-t-15 text-muted">Immer am Puls der Zeit, kennen wir uns mit den aktuellen Technolgien und Frameworks aus. Insbesondere PIM-, Shop-, CMS- und DAM-Technologien sind unser Heimathafen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--START SERVICES-->
</template>

<script>
'use strict'
export default {
  name: 'Services'
}
</script>
