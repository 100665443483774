<template>
    <!--START PARTNER-->
        <section class="section bg-light pt-5 scroll-select" id="partner">
            <div class="container">
                <div class="row margin-t-50">
                    <div class="col-lg-8 offset-lg-2">
                        <h1 class="section-title text-center">Partner</h1>
                        <div class="section-title-border margin-t-20"></div>
                        <p class="section-subtitle text-muted text-center padding-t-30 font-secondary">Eine starke Manschaft dank verlässlicher Partner</p>
                    </div>
                </div>
                <div class="row margin-t-30">
                    <div class="col-lg-4 margin-t-20">
                        <a class="logo" href="https://www.celum.com/" target="_blank">
                            <div class="services-box text-center hover-effect">
                                <img src="/images/partner/logo_celum.svg" alt="celum" height="100" />
                                <p class="padding-t-15 text-muted">Mit CELUM erstellen, organisieren und verteilen Marketing Teams Content für jeden Kanal und jede Zielgruppe. Arbeiten Sie mit Ihrem Team in agilen Workrooms in denen Sie Ihre Freigabeprozesse selbst definieren können. Reichern Sie Ihre Dateien mit Metadaten an und verwalten Sie alles in einem zentralen Content Hub. Von dort aus können Sie Ihre Assets in unterschiedliche Systeme integrieren.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 margin-t-30">
                        <a class="logo" href="https://ci-hub.com/" target="_blank">
                            <div class="services-box text-center hover-effect">
                                <img src="/images/partner/logo_cihub.png" alt="celum" height="100" />
                                <p class="padding-t-15 text-muted">CI HUB ist die führende Plattform zur Verbindung von Marken-Assets verschiedenen Asset-Repositories mit nativen Kreativapplikationen, wodurch Sie intelligenter arbeiten und schneller liefern können.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 margin-t-30">
                        <a class="logo" href="https://www.eggheads.net/" target="_blank">
                            <div class="services-box text-center hover-effect">
                                <img src="/images/partner/logo_eggheads.svg" alt="eggheads" height="100" />
                            </div>
                        </a>
                    </div>
                </div>
                <div class="row margin-t-50"></div>
            </div>
        </section>
    <!--END Partner-->
</template>

<script>
export default {
  name: 'Partner',
  props: {
  }
}
</script>
