<template>
    <!--START HOME-->
        <section class="section bg-home height-100vh scroll-select home" id="home">
            <div class="bg-overlay"></div>
            <div class="">
                <div class="">
                    <div class="container slidero">
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2 text-white text-center">
                                <h1 class="home-title">Im Sturm den richtigen Kurs anlegen</h1>
                                <p class="padding-t-15 home-desc">Die aktuelle Krise bietet auch viele Chancen. Um neue und bessere Wege zu erkennen hilft es, einen Schritt zurückzutreten und sich das große Ganze anzusehen. Wir möchten Sie mit unserer langjährigen Erfahrung im E-Business kennenlernen und Ihnen neue, effiziente Wege aufzeigen.</p>
                                <p class="padding-t-15 padding-b-10 home-desc">Dabei bilden unsere Werte das Fundament unserer Zusammenarbeit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--END HOME-->
</template>

<script>
'use strict'
export default {
  name: 'HeroSection9',
  props: {
  }
}
</script>
