import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Pages/Home'
import ContentPage from '@/components/Pages/ContentPage'
import ForgotPassword from '@/components/Pages/ForgotPassword'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/content/:id',
      component: ContentPage
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    }
  ]
})
