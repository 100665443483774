<template>
    <!--START FOOTER-->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 margin-t-20">
                        <h4>nördlicheDigitale GmbH</h4>
                        <div class="text-muted margin-t-20">
                            <ul class="list-unstyled footer-list">
                                <li><router-link to="/content/impressum">Impressum</router-link></li>
                                <li><router-link to="/content/datenschutz">Datenschutz</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 margin-t-20"></div>
                    <div class="text-muted margin-t-20"></div>
                    <div class="col-lg-4 margin-t-20">
                    </div>
                </div>
            </div>
        </footer>
        <!--END FOOTER-->
</template>

<script>
export default {
  name: 'FooterSection',
  props: {
  }
}
</script>
