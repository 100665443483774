<template>
    <div class="account-home-btn d-none d-sm-block">
      <a href="/" class="text-white"><i class="mdi mdi-home h1"></i></a>
    </div>
</template>

<script>

export default {
  name: 'HomeButton',
  components: {
  }
}
</script>
