<template>
    <a class="navbar-brand logo text-uppercase" href="/">
        <img src="/images/logo.png" alt="payment-img" height="50" />
    </a>
</template>

<script>
export default {
  name: 'BrandLogo',
  props: {
  }
}
</script>
