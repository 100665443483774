<template>
    <!--START PROCESS-->
        <section class="section bg-light scroll-select" id="start-process">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <h1 class="section-title text-center">Wie wir arbeiten</h1>
                        <div class="section-title-border margin-t-20"></div>
                        <p class="section-subtitle text-muted text-center font-secondary padding-t-30">
                            Wir ermöglichen unseren Kunden in kürzester Zeit den größtmöglichen Nutzen zu realisieren.
                            Deswegen starten wir Projekte häufig mit einer MVP-Phase, in der wir ein Projekt prototypisch und kundenzentriert in den Markt bringen.

                            Dafür starten wir das Projekt in 4 Phasen.
                        </p>
                    </div>
                </div>

                <div class="row margin-t-50">
                  <div class="col-lg-3 plan-line">
                        <div class="text-center process-box">
                            <i class="pe-7s-pen text-custom"></i>
                            <h4 class="padding-t-15">Anforderungsaufnahme</h4>
                            <p class="text-muted">Wir setzen uns mit Ihnen (möglichst vor Ort, aktuell remote) zusammen und definieren in Workshops die Anforderungen an Ihr System. Im Fokus stehen dabei die Anforderungen Ihres Unternehmens, dabei ist es unser Anliegen, alle beteiligten Fachabteilungen mitzunehmen. Wir definieren was Ihnen wirklich hilft, um noch besser zu arbeiten. Dazu gehören z.B. Prozesse, bei denen Sie tagtäglich denken: "Das könnte man doch viel besser machen!". Wir helfen Ihnen dabei, dies umzusetzen und mit Leben zu füllen.</p>
                        </div>
                    </div>

                    <div class="col-lg-3 plan-line">
                        <div class="text-center process-box">
                            <i class="pe-7s-target text-custom"></i>
                            <h4 class="padding-t-15">Beratung</h4>
                            <p class="text-muted">Durch die vorherige Analyse der individuellen Anforderungen Ihres Unternehmens empfehlen wir Ihnen das passende Produkt und eine Vorgehensweise für das anschließende Projekt.</p>
                        </div>
                    </div>

                    <div class="col-lg-3 plan-line">
                        <div class="text-center process-box">
                            <i class="pe-7s-id text-custom"></i>
                            <h4 class="padding-t-15">Prototyping / MVP</h4>
                            <p class="text-muted">Durch einen auf Sprints basierten Prozess, liefern wir zielsicher und in kurzen Abständen lauffähige Erfolge und planen mit Ihnen gemeinsam den nächsten Schritt.</p>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="text-center process-box">
                            <i class="pe-7s-target text-custom"></i>
                            <h4 class="padding-t-15">GoLive</h4>
                            <p class="text-muted">Die GoLive-Begleitung ist der Abschluss des Projekts und zugleich eine der wichtigsten und intensivsten Phasen der Zusammenarbeit.

                              Die weitere Betreuung im Nachgang ist für uns selbstverständlich! Wir kennen Ihr Projekt und vor allem die Menschen in Ihrem Unternehmen. Das Team ist eingespielt, kann so effizient weitere Anforderungen und damit einen sehr schnellen Ausbau Ihrer Systemlandschaft umsetzen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--END PROCESS-->
</template>

<script>
'use strict'
export default {
  name: 'StartProcess',
  props: {
  }
}
</script>
